import ruLocale from 'antd-mobile/es/locales/ru-RU'
import { CopyAlert } from 'components/copy-alert'
import dayjs from 'dayjs'
import 'dayjs/locale/ru.js'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import ReactDOM from 'react-dom/client'
import './index.css'
import { RouterProvider } from 'react-router-dom'
import { router } from 'router'
import { App } from './App.tsx'
import { ConfigProvider } from 'antd-mobile'
import { Provider, ErrorBoundary } from '@rollbar/react'

import { registerSW } from 'virtual:pwa-register'
import { ROLLBAR_CONFIG } from './const/rollbar.const.ts'
import { initGoogleAnalytics } from './utils/googleAnalytics.ts'
import { initYandexMetrika } from './utils/yandexMetrika.ts'

import * as Sentry from '@sentry/react'

registerSW({ immediate: true })

initGoogleAnalytics()
initYandexMetrika()

dayjs.locale('ru')
dayjs.extend(customParseFormat)

Sentry.init({
  // dsn: 'http://a3f0fc970b28b3b40fe82d7c7e785e77@31.41.154.177:9000/3',
  dsn: 'https://62510104571a17a3a385c004ac3ecfae@o4509054268997632.ingest.de.sentry.io/4509054274109520',
  tracesSampleRate: 1.0,
  integrations: [Sentry.browserTracingIntegration()],
  environment: import.meta.env.MODE,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider config={ROLLBAR_CONFIG}>
    <ErrorBoundary>
      <ConfigProvider
        locale={{
          ...ruLocale,
          Calendar: {
            ...ruLocale.Calendar,
            confirm: 'Подтвердить',
            today: 'Сегодня',
          },
        }}
      >
        <App>
          <CopyAlert />
          <RouterProvider router={router} />
        </App>
      </ConfigProvider>
    </ErrorBoundary>
  </Provider>
)
